import { render, staticRenderFns } from "./Infomation.vue?vue&type=template&id=7352bcaa&scoped=true&"
import script from "./Infomation.vue?vue&type=script&lang=js&"
export * from "./Infomation.vue?vue&type=script&lang=js&"
import style0 from "./Infomation.vue?vue&type=style&index=0&id=7352bcaa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7352bcaa",
  null
  
)

export default component.exports