import Vue from 'vue';
import VueI18n from 'vue-i18n';
import vi from './locales/vi.json';
import cn from './locales/cn.json';

// 使用 VueI18n 插件
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'vi', // 设置默认语言 预约
    messages: {
        vi,
        cn
    },
});

export default i18n;