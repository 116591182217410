<template>
  <div class="container page">
    <van-nav-bar class="nav-bar" :title="$t('account_details')">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="convention-item">
      <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab :title="$t('recharge')">
          <van-empty v-if="clist.length === 0" :description="$t('no_data')" />
          <div v-else class="item_list" v-for="(v, key) in clist" :key="key">
            <div class="topInfo">
              <span>{{ v.status_text }}</span>
              <span>{{ $t('amount') }}：+{{ v.money }}</span>
            </div>
            <div class="desc">
              <span>{{ $t('description') }}：{{ v.desc }}</span>
            </div>
            <div class="time">
              <span>{{ $t('review_time') }}：{{ v.create_time }}</span>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('withdraw')">
          <van-empty v-if="wlist.length === 0" :description="$t('no_data')" />
          <div v-else class="item_list" v-for="(v, key) in list" :key="key">
            <div class="topInfo">
              <span v-if="v.status === 2" style="color: #07c160">{{ v.status_text }}</span>
              <span v-else>{{ v.status_text }}</span>
              <span>{{ $t('amount') }}：-{{ v.money }}</span>
            </div>
            <div class="desc">
              <span>{{ $t('description') }}：{{ v.desc }}</span>
            </div>
            <div class="time">
              <span>{{ $t('submission_time') }}：{{ v.create_time }}</span>
            </div>
            <div class="time">
              <span>{{ $t('review_time') }}：{{ v.update_time }}</span>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('task_record')">
          <div class="main">
              <van-empty v-if="list.length === 0" :description="$t('no_data')" />
              <div v-else class="item_list" v-for="(v,key) in list" :key="key">
                <div class="lottery_info">
                  <van-image class="cover" :src="v.ico">
                    <template v-slot:loading>
                      <van-loading type="spinner"/>
                    </template>
                  </van-image>
                  <span class="period-number">{{v.expect}}</span>
                  <span class="period-number">{{v.name}}</span>
                </div>
                <div class="recent">
                  <div class="kuaisan-ball left">
                    <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[0] + '.png' " >
                      <template v-slot:loading>
                        <van-loading type="spinner"/>
                      </template>
                    </van-image>
                    <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' " >
                      <template v-slot:loading>
                        <van-loading type="spinner"/>
                      </template>
                    </van-image>
                    <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' " >
                      <template v-slot:loading>
                        <van-loading type="spinner"/>
                      </template>
                    </van-image>
                    <span class="res-des middle">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span>
                    <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t("lottery.male") : $t("lottery.female")}}</span>
                    <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t("lottery.even") : $t("lottery.odd")}}</span>
                  </div>
                </div>
                <div class="topInfo">
                  <span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
                  <span v-else>{{v.status_text}}</span>
                  <span>{{$t('so_go')}}：{{v.money}}</span>
                </div>
                <div class="time">
                  <span>{{$t('odd_time')}}：{{v.create_time}}</span>
                </div>
                <div class="time">
                  <span>{{$t('odd_entime')}}：{{v.update_time}}</span>
                </div>
              </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[],
      wlist:[],
      clist:[],
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('refresh_success'));
        this.isLoading = false;
      }, 500);
    },
    getUserGameList(){
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res=>{
        if(res.code === 200){
          console.log(res.data)
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawList(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_list'
      }).then(res=>{
        if(res.code === 200){
          this.wlist = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserRechargeList(){
      this.$http({
        method: 'get',
        url: 'user_get_Recharge_list'
      }).then(res=>{
        if(res.code === 200){
          this.clist = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameList();
      this.getUserWithdrawList();
      this.getUserRechargeList();
    }
  }
};
</script>

<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}
::v-deep .van-tabs__line {
  background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.card {
  background-color: #8a637d;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 0.8125rem;
  margin: 0.625rem auto;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  height: 80px;
  line-height: 80px;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #0bdab0;
  font-size: 1.125rem;
}
.rig-content {
  font-size: 20px;
  // margin-top: 10px;
}
.address {
  width: 90%;
  margin: 0 auto;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}
.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .cover{
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}
.item_list  .period-number{
  margin-left: 50px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}
.item_list .lottery_info{
  display: flex;
}
.recent {
  display: flex;
  align-items: center;
  height: 100px;
}
.kuaisan-ball .left{
  justify-content: flex-start;
}
.kuaisan-ball{
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img{
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #000;
}
.kuaisan-ball .res-des.middle{
  width: 15%;
  font-size: 25px;
}
</style>
